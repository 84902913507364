import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import cssVars from "css-vars-ponyfill";
import "bootstrap/dist/css/bootstrap-grid.css";
import * as Sentry from "@sentry/browser";

import appPackage from "../package.json";

import "./theme/common/index.css";
import en from "./translations/en.json";
import it from "./translations/it.json";
import environment from "./environment/index.js";
import store from "./app/store";

if (!["localhost", "127.0.0.1"].includes(location.hostname)) {
  const { organizationId, organizationName } = environment;
  Sentry.init({
    dsn:
      "https://f8c883f58ed2495ca22fdd7eaf8c3bd4@o90647.ingest.sentry.io/5192221",
  });
  Sentry.configureScope(scope => {
    scope.setTags({
      environment: process.env.REACT_APP_ENV || "",
      organizationName,
      organizationId,
      release: appPackage.version,
    });

    scope.setContext("state", { ...store.getState(), transfer: undefined });
  });
}

cssVars({
  watch: true,
});

// initialize language settings
i18next.use(initReactI18next).init({
  resources: {
    en,
    it,
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

Modal.setAppElement("#root");

function setAppTitle() {
  const title = document.createElement("title");
  title.innerText = environment.organizationName;

  document.getElementsByTagName("head")[0].appendChild(title);
}

function loadOsuWidget() {
  return new Promise((resolve, reject) => {
    const tag = document.createElement("script");
    const link =
      environment.payosuScript || "//widget.sandbox.payosu.com/index.js";

    tag.src = link;
    tag.type = "text/javascript";

    tag.onload = resolve;
    tag.onerror = reject;

    document.head.appendChild(tag);
  });
}

(async () => {
  import(`./theme/${process.env.REACT_APP_NAME}/index.css`);

  setAppTitle();
  await loadOsuWidget()
    .then(data => console.log("[OSU]: Loaded! Data: ", data))
    .catch(err => console.log("[OSU]: Error! Data: ", err));
  const { default: App } = await import("./app");
  ReactDOM.render(<App />, document.getElementById("root"));
})();
